import { WhyContentContainer } from "./WhyContent.styled";
import { Trans, useTranslation } from "react-i18next";
import { ReactNode } from "react";
import NoErrorsImage from "../../../assets/images/svg/no-errors.svg";
import SavingTimeImage from "../../../assets/images/svg/saving-time.svg";
import AutomationImage from "../../../assets/images/svg/automation.svg";
import AdaptabilityImage from "../../../assets/images/svg/adaptability.svg";
import AdministrationImage from "../../../assets/images/svg/administration.svg";
import {
  WhyContentCard,
  WhyContentCardProps,
} from "./WhyContentCard/WhyContentCard";

export const WhyContent = (props: WhyContentProps) => {
  const { t } = useTranslation();
  const cards: WhyContentCardProps[] = [
    {
      title: t("sectionWhy.cards.card1.title"),
      text: t("sectionWhy.cards.card1.text"),
      image: NoErrorsImage,
    },
    {
      image: SavingTimeImage,
      title: t("sectionWhy.cards.card2.title"),
      text: t("sectionWhy.cards.card2.text"),
    },
    {
      image: AutomationImage,
      title: t("sectionWhy.cards.card3.title"),
      text: t("sectionWhy.cards.card3.text"),
    },
    {
      image: AdaptabilityImage,
      title: t("sectionWhy.cards.card4.title"),
      text: t("sectionWhy.cards.card4.text"),
    },
    {
      image: AdministrationImage,
      title: t("sectionWhy.cards.card5.title"),
      text: t("sectionWhy.cards.card5.text"),
    },
  ];

  return (
    <WhyContentContainer>
      {cards &&
        cards.map((x, index) => (
          <WhyContentCard key={index} {...x}></WhyContentCard>
        ))}
    </WhyContentContainer>
  );
};

export interface WhyContentProps {}
