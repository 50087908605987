import { useState, useMemo } from "react";
import {
  authScopeSetHelper,
  authScopeStringGetHelper,
} from "../util/authScopeHelpers";
import { getTheme } from "../themes";
import {
  Theme,
  getNextTheme,
  themeStorageKey,
  themes,
} from "../constants/themeConstants";
import { DefaultTheme } from "styled-components";

export interface ColorModeContextType {
  changeTheme: (newTheme: string) => void;
  theme: Theme & DefaultTheme;
  currentTheme: string | undefined;
}

const useTheme = () => {
  const currentColorMode =
    authScopeStringGetHelper(themeStorageKey) || themes[0]?.name;
  const [currentTheme, setCurrentTheme] = useState(currentColorMode);

  const changeTheme = (newTheme: string | undefined = undefined) => {
    const nextMode = newTheme || getNextTheme(currentTheme);
    authScopeSetHelper(themeStorageKey, nextMode);
    setCurrentTheme(nextMode);
  };

  const theme = useMemo(() => getTheme(), [currentTheme]);

  return [changeTheme, theme, currentTheme] as const;
};

export default useTheme;
