import { format as formatDate } from "date-fns";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslations from "./resources/en";
import srTranslations from "./resources/sr";

i18n.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  debug: false,
  supportedLngs: ["en", "sr"],
  resources: {
    en: {
      translation: enTranslations,
    },
    sr: {
      translation: srTranslations,
    },
  },
  interpolation: {
    format: (value, format) => {
      if (value instanceof Date) {
        return formatDate(value, format ?? "sr");
      }

      return value;
    },
  },
});

export default i18n;
