import styled, { css } from "styled-components";
import { WhyContentCardProps } from "./WhyContentCard";

export const WhyContentCardWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.transparent};
  gap: 32px;
  border-radius: 16px;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px 32px;
  display: flex;
  box-shadow: 0 4px 17px #00000040;

  &:last-child {
    grid-area: span 1 / span 2 / span 1 / span 2;
  }

  @media screen and (max-width: 991px) {
    flex-flow: column;
  }

  @media screen and (max-width: 767px) {
    //width: 100%;
  }
`;

export const WhyContentCardTitle = styled.h3`
  margin-top: 0;
  margin-bottom: 28px;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
`;

export const WhyContentCardText = styled.div`
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 18px;
`;

export const WhyContentCardContainer = styled.div<WhyContentCardProps>``;
