import styled from "styled-components";
import { Select, SelectChangeEvent } from '@mui/material';
export interface NavBarProps {
  isOpened: boolean;
}
export interface ButtonWrapperProps {
  isMobile: boolean;
  isTablet: boolean;
}
export interface NavBarExtendedProps {
  isOpened: boolean;
  closeMenu: () => void;
}
export interface OptionProps {
  isSelected: boolean;
}
export interface LanguageSelectProps {
  value: String;
  onChange: (event: SelectChangeEvent<string>) => void;
}
export const HeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9;
  background-color: ${(props) => props.theme.colors.dawnPink};
  position: fixed;
  width: 100%;
  top: 0;
  overflow: visible;
  box-shadow: 0 2px 6px ${(props) => props.theme.colors.boxShadow};
`;
export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;
export const LogoImage = styled.img`
  width: 40px;
  margin-right: 8px;
`;
export const LogoText = styled.span`
  font-size: 1.5rem;
  color: ${(props) => props.theme.colors.gray};
  strong {
    font-weight: bold;
  }
`;
export const NavDesktop = styled.nav`
  margin-top: 10px;
  ul {
    display: flex;
    list-style: none;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
  }
`;
export const NavContainer = styled.div`
  position: relative;
  width: 100%;
`;
export const NavMobile = styled.nav<NavBarProps>`
  position: fixed;
  top: 86px;
  left: 0;
  opacity: ${(props) => (props.isOpened ? "1" : "0")};
  height: ${(props) => (props.isOpened ? "250px" : "0px")};
  overflow: ${(props) => (props.isOpened ? "unset" : "hidden")};
  background-color: ${(props) => props.theme.colors.white};
  z-index: 9;
  width: 100%;
  -webkit-transition: height 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  box-shadow: 0 3px 7px #0333;
  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
  }
  ul li {
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
  }
`;
export const NavTablet = styled.nav<NavBarProps>`
  position: fixed;
  top: 86px;
  left: 0;
  opacity: ${(props) => (props.isOpened ? "1" : "0")};
  height: ${(props) => (props.isOpened ? "40px" : "0px")};
  overflow: ${(props) => (props.isOpened ? "unset" : "hidden")};
  background-color: ${(props) => props.theme.colors.white};
  z-index: 999999;
  width: 100%;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  box-shadow: 0 3px 7px #0333;
  ul {
    display: flex;
    gap: 58px;
    flex-direction: row;
    list-style: none;
    align-items: center;
    margin: 0;
    padding: 0;
    height: 100%;
  }
  @media screen and (max-width: 833px) {
    ul {
      gap: 50px;
    }
  }
  @media screen and (max-width: 794px) {
    ul {
      gap: 40px;
    }
  }
  @media screen and (max-width: 742px) {
    ul {
      gap: 32px;
    }
  }
  @media screen and (max-width: 702px) {
    ul {
      gap: 27px;
    }
  }
  @media screen and (max-width: 690px) {
    ul {
      gap: 11px;
    }
  }
`;
export const HeaderContainerWrap = styled.div`
  z-index: 5;
  background-color: ${(props) => props.theme.colors.blackSecondary};
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
`;
export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1260px;
  min-height: 30px;
  margin-left: auto;
  margin-right: auto;
`;
export const NavLink = styled.a`
  color: ${(props) => props.theme.colors.easternBlue};
  letter-spacing: 0.25px;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none;
`;
export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  margin: auto 0;
  margin-left: 14px;
  .btn {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
    letter-spacing: 2px;
    text-transform: none;
    mix-blend-mode: normal;
    border-radius: 8px;
    padding: 10px 18px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    display: block;
    transition: all 0.2s;
    box-shadow: 0 5px 8px ${(props) => props.theme.colors.boxShadow};
    text-decoration: none;
  }
  .btn:hover {
    background-color: ${(props) => props.theme.colors.primaryDarker};
  }

  @media screen and (max-width: 991px) {
    margin-left: 0;
  }
`;
export const AvatarDiv = styled.div`
  margin-right: "35px";
`;
export const LogoutContainer = styled.div`
  width: 118px;
  height: 47px;
  padding: 10px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    cursor: pointer;
  }
`;
export const LabelWithPointer = styled.label`
  margin-right: 10px;
  cursor: pointer;
`;
export const LogoutIcon = styled.img`
  width: 20px;
  height: 20px;
`;
export const LanguageSelect = styled(Select) <LanguageSelectProps>`
  position:relative;
  z-index: 9999999999999; 
  color: #161616!important;
  border: none;
  background: transparent;
  font-weight:700!important;
  opacity:.6!important;
  cursor:pointer;
  font-family: "Inter", sans-serif!important;
  font-size:14px!important;
  border:none!important;
  border-radius: 20px;
  border-image-width:0px!important;
  padding: 6px !important;
  line-height: unset!important;
  margin-top:5px;
  & .MuiOutlinedInput-notchedOutline {
    border:none;
  }
  & > div {
    padding:0px;
  }
  & .MuiSelect-select {
    padding-right:20px!important; 
  }
    
  @media screen and (max-width: 600px) {
    margin-top: 0px;
    padding-right:10px;
    margin-bottom: 5px;
    margin-left: 7px;
  }
`;
