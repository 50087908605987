import styled, { css } from "styled-components";
import { FAQContentCardProps } from "./FAQQuestion";
import { ReactComponent as FAQIconSVG } from "../../../../assets/images/svg/faq_arr-bottom.svg";

export const FAQWrapper = styled.div`
  z-index: 4;
  width: 100%;
  height: auto;
  padding-bottom: 40px;
  overflow: visible;

  text-align: left;
  margin: 0 auto;
  display: inline-block;
  position: relative;

  &:last-child {
    grid-area: span 1 / span 2 / span 1 / span 2;
  }

  @media screen and (max-width: 991px) {
    flex-flow: column;
  }

  @media screen and (max-width: 767px) {
    //width: 100%;
  }
`;

export const FAQHeader = styled.div<FAQContentCardProps>`
  vertical-align: top;
  color: ${(props) => props.theme.colors.black};
  text-align: left;
  white-space: nowrap;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  position: relative;

  user-select: none;
  cursor: pointer;

  z-index: 1;
  border-left: 16px solid ${(props) => props.theme.colors.primaryDarker};
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 12px;

  justify-content: space-between;
  align-items: center;

  width: auto;
  height: auto;
  padding: 32px 40px 32px 68px;

  font-size: 28px;
  line-height: 150%;
  display: flex;
  overflow: hidden;
  box-shadow: 0 10px 10px ${(props) => props.theme.colors.boxShadow};

  &:hover {
    background-image: linear-gradient(
      ${(props) => props.theme.colors.FAQBackground},
      ${(props) => props.theme.colors.FAQBackground}
    );
  }

  transition: 0.3s all linear;
  ${(props) =>
    props?.isOpen &&
    css`
      background-color: ${(props) => props.theme.colors.FAQBackground};
    `}

  @media screen and (max-width: 991px) {
    padding-left: 20px;
  }
`;

export const FAQBody = styled.div<FAQContentCardProps>`
  background: #ddd;
  display: flex !important;

  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  justify-content: center;
  align-items: center;
  margin-top: -16px;

  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 10px ${(props) => props.theme.colors.boxShadow};

  max-width: 1120px;
  height: 200px;
  display: block;
  background-color: ${(props) => props.theme.colors.FAQBackground};
  transition: 0.3s all linear;

  padding: 40px 40px 40px;

  @media screen and (max-width: 767px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  ${(props) =>
    !props?.isOpen &&
    css`
      height: 0;
      padding-top: 0;
      padding-bottom: 0;
    `}

  @media screen and (max-width: 479px) {
    font-size: 14px;
  }
`;

export const FAQuestion = styled.span<FAQContentCardProps>`
  -webkit-text-stroke-color: ${(props) => props.theme.colors.primaryDarker};
  white-space: normal;
  word-break: normal;
  flex: 0 auto;
  flex-wrap: wrap;
  column-gap: 5px;
  width: 90%;
  font-weight: 600;

  @media screen and (max-width: 991px) {
    flex: 0 auto;
    width: auto;
    font-size: 24px;
    line-height: 120%;
    display: flex;
  }

  @media screen and (max-width: 767px) {
    overflow: visible;
  }

  @media screen and (max-width: 479px) {
    font-size: 18px;
  }

  transition: 0.3s all linear;
  ${(props) =>
    props?.isOpen &&
    css`
      color: ${(props) => props.theme.colors.primaryDarker};
    `}
`;

export const FAQIcon = styled(FAQIconSVG) <FAQContentCardProps>`
  flex: none;
  width: 52px;
  height: 52px;

  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;

  transition: 0.3s all ease-in-out;

  ${(props) =>
    props?.isOpen &&
    css`
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(180deg) skew(0deg, 0deg);
      transform-style: preserve-3d;
    `}
`;

export const FAQQuestions = styled.div`
  width: 100%;
  margin-bottom: 64px;
`;

export const FAQContentText = styled.div`
  margin-bottom: 16px;
  padding-bottom: 0;
  font-size: 32px;
  font-weight: 600;
  line-height: 150%;
`;
