import { primaryThemeColors } from "./primaryThemeColors";
import { primaryThemeFonts } from "./primaryThemeFonts";
import { Theme } from "../../constants/themeConstants";

const primary: Theme = {
  colors: primaryThemeColors,
  font: primaryThemeFonts,
};

export default primary;
