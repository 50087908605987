import { Outlet } from "react-router-dom"; //Navigate,
//import { PAGES } from "../constants/pages";
//import { Roles } from "src/enums/Roles";
//import { useAuthStore } from "src/store/user/useAuthStore";

export const PublicRoute = () => {
  // const { token, role } = useAuthStore((state: { token: any; role: any }) => ({
  //   token: state.token,
  //   role: state.role,
  // }));

  // TODO: Edit default route for user when logged in
  // const ROUTE= role==Roles.SUPERADMIN ? PAGES.ADMIN_LANDING_PAGE : PAGES.LOGIN_ROUTE

  //const ROUTE = "/";
  // if (token) {
  //   return <Navigate to={ROUTE} replace />;
  // }
  return <Outlet />;
};
