import { ScrollTopContainer } from './ScrollToTop.styled';
import { ReactComponent as ScrollToTopImg } from '../../../assets/images/svg/scroll-to-top.svg';
import { useEffect, useState } from 'react';

export const ScrollToTop = () => {
    const [hasScrolled, setHasScrolled] = useState(false);

    const handleScroll = () => {
        const scrollTop = document.documentElement.scrollTop;

        if (scrollTop > 150) {
            setHasScrolled(true);
        } else {
            setHasScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll); // Cleanup on unmount
        };
    }, []); // Empty dependency array ensures this runs once on mount

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Smooth scroll back to top
        });
    };

    return (
        <ScrollTopContainer onClick={scrollToTop} isVisible={hasScrolled}>
            <ScrollToTopImg />
        </ScrollTopContainer>
    );
};
