export const secondaryThemeColors = {
  primary: "#ff7f66",
  primaryDarker: "#f3674c",
  white: "#fff",
  black: "#161616",
  transparent: "#fff0",
  dividerLinesOrange: "#ff7f66cc",
  lightOrange: "#f3e3e0",
  text: "#1a1b1f",
  burger: "#a6b1bf",
  burgerSecondary: "#313131",
  blackSecondary: "#0000",
  easternBlue: "#1a1b1f",
  gray: "#333",
  dawnPink: "#F5EAE8",
  boxShadow: "#0003",
};
