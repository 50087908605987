import { BurgerBottomContainer, BurgerBottomLine, BurgerBottomProps } from './BurgerBottom.styled';

export const BurgerBottom: React.FC<BurgerBottomProps> = ({ isOpened, onClick }) => {
    return ( 
         <BurgerBottomContainer isOpened={isOpened} onClick={onClick}>
            <BurgerBottomLine isOpened={isOpened} />
            <BurgerBottomLine isOpened={isOpened}/>
            <BurgerBottomLine isOpened={isOpened}/>
        </BurgerBottomContainer>         
    );
}