export function authScopeGetHelper(key: string) {
  return JSON.parse(localStorage.getItem(key) ?? "");
}

export function authScopeStringGetHelper(key: string) {
  return localStorage.getItem(key);
}

export function authScopeSetHelper(key: string, value: any) {
  localStorage.setItem(key, value);
}

export function authScopeRemoveHelper(key: string) {
  localStorage.removeItem(key);
}

export function authScopeClearHelper() {
  localStorage.clear();
}
