import styled from "styled-components";
import { ContactContentProps } from "./ContactContent";

export const ContactContentContainer = styled.div<ContactContentProps>`
  font-size: 20px;
  display: flex;
  flex-direction: row;
  gap: 120px;
  width: 100%;
  height: auto;

  @media screen and (max-width: 991px) {
    flex-flow: column;
  }

  @media screen and (max-width: 479px) {
    gap: 80px;
  }
`;

export const ContactText = styled.p`
  color: ${(props) => props.theme.colors.white};
  width: 50%;
  font-size: 20px;
  font-weight: 400;
  line-height: 150%;

  margin-top: 0;
  margin-bottom: 10px;

  @media screen and (max-width: 991px) {
    width: 100%;
  }

  @media screen and (max-width: 479px) {
    margin-bottom: 0;
    font-size: 18px;
  }
`;

export const ContactInfo = styled.div`
  align-self: center;
  width: 50%;

  @media screen and (max-width: 991px) {
    width: 100%;
  }
`;
export const ContactInfoEmail = styled.a`
  gap: 16px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  text-decoration: none;
  display: flex;

  @media screen and (max-width: 479px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
`;
export const ContactInfoPhone = styled.a`
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  text-decoration: none;
  display: flex;
`;

export const TextLink = styled.a`
  color: ${(props) => props.theme.colors.white};
  font-weight: 700;
  text-decoration: none;
`;

export const ContactInfoLink = styled.span`
  color: ${(props) => props.theme.colors.white};
  font-size: 24px;
  line-height: 150%;
  text-decoration: none;
`;
