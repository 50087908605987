import { Link } from "@mui/material";
import {
  SectionWrapper,
  SectionTitle,
  SectionDecoration,
  SectionContainer,
} from "./Section.styled";
import { Trans, useTranslation } from "react-i18next";
import { ReactNode } from "react";
import SDecoration from "../../assets/images/svg/section-decoration.svg";

export interface SectionProps {
  //$backgroundColor: string;
  id?: string;
  children: ReactNode;
  title?: string;
  showSectionDecoration?: boolean;
  backgroundImage?: string;
  backgroundPosition?: string;
}

export const Section: React.FC<SectionProps> = (props: SectionProps) => {
  //props = { ...defaultProps, ...props };

  const { t } = useTranslation();

  return (
    <SectionContainer id={props?.id} {...props}>
      <SectionWrapper>
        {props?.showSectionDecoration && (
          <SectionDecoration src={SDecoration} />
        )}
        {props?.title && (
          <SectionTitle>
            <Trans i18nKey={props?.title}></Trans>
          </SectionTitle>
        )}
        {props?.children}
      </SectionWrapper>
    </SectionContainer>
  );
};

Section.defaultProps = {
  id: undefined,
  children: undefined,
  title: undefined,
  showSectionDecoration: false,
  backgroundImage: undefined,
  backgroundPosition: "0 0, 0 0, 50% 0%",
};
