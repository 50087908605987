import { Link } from "@mui/material";
import {
  WhyContentCardWrapper,
  WhyContentCardTitle,
  WhyContentCardText,
  WhyContentCardContainer,
} from "./WhyContentCard.styled";
import { Trans, useTranslation } from "react-i18next";
import { ReactNode } from "react";
import SDecoration from "../../assets/images/svg/section-decoration.svg";

export interface WhyContentCardProps {
  id?: string;
  children?: ReactNode;
  title?: string;
  text?: string;
  image?: string;
}

export const WhyContentCard = (props: WhyContentCardProps) => {
  //props = { ...defaultProps, ...props };

  const { t } = useTranslation();

  return (
    <WhyContentCardWrapper>
      <img src={props.image} />
      <WhyContentCardContainer>
        {props?.title && (
          <WhyContentCardTitle>
            <Trans i18nKey={props?.title}></Trans>
          </WhyContentCardTitle>
        )}
        {props?.text && (
          <WhyContentCardText>
            <Trans i18nKey={props?.text}></Trans>
          </WhyContentCardText>
        )}
      </WhyContentCardContainer>

      {props?.children}
    </WhyContentCardWrapper>
  );
};
