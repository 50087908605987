import {
  CtaContentContainer,
  CtaButtons,
  CtaText,
  CtaImage,
} from "./CtaContent.styled";
import { Trans, useTranslation } from "react-i18next";
import { ReactNode } from "react";
import { ButtonLink } from "../../ButtonLink/ButtonLink";

export const CtaContent = (props: CtaContentProps) => {
  const { t } = useTranslation();

  return (
    <CtaContentContainer>
      <CtaText>
        <Trans i18nKey={"sectionInfo.text"} />
      </CtaText>
      <CtaButtons>
        <ButtonLink href="#contact" largeButton color="secondary" outlinedBtn>
          {<Trans i18nKey={"common.contactUs"} />}
        </ButtonLink>
        <ButtonLink href="#contact" largeButton color="secondary">
          {<Trans i18nKey={"common.tryForFree"} />}
        </ButtonLink>
      </CtaButtons>
    </CtaContentContainer>
  );
};

export interface CtaContentProps {
  //$backgroundColor: string;
}
export interface CtaImageProps {
  //$backgroundColor: string;
  id?: string;
  children: ReactNode;
  title?: string;
  showSectionDecoration?: boolean;
}
