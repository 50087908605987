export default {
  app: {
    title: "Test 123",
  },
  refresh: {
    title: "Are you active?",
    cta: "You were registered as not active, please confirm that you are active in the next minute, if you don't you will be logged out.",
  },
  common: {
    language: "Language",
    english: "English",
    serbian: "Serbian",
    close: "Close",
    dataGridExample: "Data Grid Example",
    trademark: "TM",
    search: "Search",
    error: "Error",
    continue: "Continue",
    labelUsername: "Username",
    labelEmail: "Email",
    labelPassword: "Password",
    next: "Next",
    nextPage: "Next page",
    previousPage: "Previous page",
    back: "Back",
    goBack: "Go Back",
    ok: "Ok",
    done: "Done",
    confirm: "Confirm",
    printDownload: "Print/Download",
    cancel: "Cancel",
    remove: "Remove",
    invite: "Invite",
    save: "Save",
    complete: "Complete",
    download: "Download",
    yes: "Yes",
    no: "No",
    to: "to",
    select: "Select...",
    none: "None",
    date: {
      range: "{{start}} to {{end}}",
    },
    apply: "Apply",
    contactUs: "Contact us",
    tryForFree: "Free Trial",
    supportLink: "im.support@tremium.net",
    phoneNumber: "060/366-02-71",
  },
  pageTitles: {
    Home: "Home Page | Tremium Software",
    About: "About Us | Tremium Software",
    Career: "Career Page | Tremium Software",
  },
  pages: {
    home: "Home",
    login: "Login",
    register: "Register",
    forgotPassword: "Forgot password",
    error: "Error",
    notFound: "Not Found",
    profile: "Profile",
    settings: "Settings",
    dashboard: "Dashboard",
    about: "About",
    careers: "Careers",
    contact: "Contact",
  },
  register: {
    registerTitle: "Register",
    usernameRequired: "Username is required.",
    emailFormat: "Invalid email address format.",
    emailRequired: "An email or username is required.",
    passwordLength: "Your password contain between 8 and 50 characters.",
    passwordRequired: "A Password is required.",
  },
  login: {
    welcome: "React template",
    dontHaveAccount: "Don't have an account? ",
    emailFormat: "Invalid email address format.",
    emailRequired: "An email or username is required.",
    noUsers: "There are no users with that email.",
    passwordStrength: "Your password is {{strength}}.",
    passwordLength: "Your password contain between 8 and 50 characters.",
    signUpRecommendation: "Sign up",
    email: "Please enter your email address or username to log in:",
    logInTitle: "Log In",
    logIn: "Log In",
    signUp: "Sign Up",
    usernameRequired: "Username is required.",
    passwordRequired: "A Password is required.",
    forgotYourPassword: "Forgot your password?",
    forgotPasswordEmail: "Email",
    useDifferentEmail: "Use different email address or username",
  },
  password: {
    weak: "weak",
    average: "average",
    good: "good",
    strong: "strong",
  },
  forgotPassword: {
    title: "Forgot Password",
    label: "Send email",
    emailRequired: "An email is required.",
    emailFormat: "Invalid email address format.",
    forgotPassword: {
      title: "Forgot Password",
      subtitle:
        "Please answer the security question to gain access to your account:",
      label: "Reset Password",
    },
  },
  notFound: {
    text: "We're sorry but we couldn't find the page you were looking for.",
    goBack: "Go back to homepage",
  },
  errorPage: {
    text: "We're sorry, an internal server error came up. Please be patient or try again later.",
    goBack: "Go back to homepage",
    logout: "Logout",
  },
  apiErrors: {
    ClientIpAddressIsNullOrEmpty: "Client Ip address is null or empty",
    UsernameDoesNotExist: "Username does not exist",
    WrongCredentials: "Wrong credentials",
    SomethingWentWrong: "Something went wrong",
    WrongPasswordAccountIsLocked: "Wrong credentials, account is locked",
    AccountIsLocked: "Account is locked",
  },
  footer: {
    text: "Inspect Master <3> Copyright © 2023 </3> <1></1> <2>Tremium Software.</2> All rights reserved.",
    navItems: {
      item1: "Home",
      item2: "Benefits",
      item3: "Demo",
      item4: "Help",
      item5: "Contact",
    },
  },
  header: {
    navItems: {
      item1: "Home",
      item2: "Benefits",
      item3: "Help",
      item4: "Contact",
      item5: "Sign In"
    },
  },
  sectionHero: {
    title: "Process Verification. Control Automation. Time Savings.",
    text: "Inspect Master is your go-to solution for workplace safety inspections. Through automation, standardization, and structured data management, our platform resolves critical challenges in traditional safety inspection workflows.",
    buttonLabel: "Contact Us",
  },

  sectionWhy: {
    title: "Why Inspect Master?",
    cards: {
      card1: {
        title: "Automation",
        text: "The inspection process is automated from the very beginning of the inspection to the creation of the expert report as the final product. Automating the process saves time and provides efficiency in operations.",
      },
      card2: {
        title: "Adaptability",
        text: "Using personalized templates, our platform enables the customization of the inspection process for any type of examination.",
      },
      card3: {
        title: "Error-Free",
        text: "By preparing templates that guide precisely through the inspection process, the likelihood of errors is reduced, and efficiency in operations is increased.",
      },
      card4: {
        title: "Time Savings",
        text: "Forget about time-consuming procedures and lengthy report creation. Inspect Master automatically generates expert reports, saving time on data entry and processing.",
      },
      card5: {
        title: "Administration and Structure",
        text: "Our application offers easy management of all parameters needed for creating findings. The expert report is the main document of the application, and the system allows for searching and filtering data according to needs.",
      },
    },
  },
  sectionInfo: {
    text: "Manual data entry and lack of documentation become a thing of the past with our software solution. Inspect Master enables quick and efficient data entry via computer, phone, and tablet, simplifying fieldwork. <br/> In order to accelerate and standardize the inspection process, our application generates an expert report created according to pre-made templates and in compliance with current regulations.",
  },
  sectionMakePossible: {
    title: "What Does <br /> Inspect Master Provide?",
    items: [
      "Ability to Enter Inspection Results Using Various Formats, Including Text, Images, and Tables",
      "Creation of Expert Reports After Inspection Data Input",
      "Seamlessly Export Expert Reports in a PDF Format",
      "Automatic Sending of Expert Reports via Email",
      "Ability to Copy Existing Reports",
      "Use Tablets and Smartphones for Quick and Efficient Data Entry",
      "Archiving Expert Reports and Storing Backups in the Cloud",
      "Ability to Create Templates for Automating the Inspection of Processes and Equipment According to Industry-Specific Requirements and Standards",
      "Search and Quick Access to All Expert Reports",
    ],
  },
  sectionTrial: {
    title: "Sign up for the first <br /> month free!",
    text1: "The Free Version Includes:",
    text2:
      "After the One-Month Period, Please Contact Us for Pricing Options and License Renewal.",
    trialList: [
      "Unlimited Users and Expert Reports",
      "Instant Report Delivery via Email Upon Closure",
      "Customizable Printing Options and Access to Templates",
      "Ability to Duplicate Templates and Findings",
      "Cloud Storage for Reports at the Time of Closure",
      "Document Management Features",
    ],
  },
  sectionFAQ: {
    title: "Frequently Asked Questions",
    note: "Still have questions?",
    questions: {
      question1: {
        question: "How to Register?",
        answer:
          "With just one call or email! The Inspect Master support team will set up the first account for your team, granting you administrator privileges. After that, the administrator can add other team members and assign them different roles within the platform.",
      },
      question2: {
        question: "What Are the Key Components of Inspect Master?",
        answer:
          "Scanning subjects, composed of multiple sections, form a core part of the application. These are created prior to the inspection to streamline subsequent evaluations. In addition to scanning subjects, expert reports are the final documents produced after inspections, generated by entering the results gathered during the process.",
      },
      question3: {
        question: "How Are the Inspection <i>parameters</i> predefined?",
        answer:
          "Within the platform, essential parameters need to be defined: the company's areas of operation  - finding types, the regulations and standards applicable to inspections, the instruments used for conducting inspections, and the clients for whom the inspections are performed.",
      },
      question4: {
        question: "How Are Scanning <i>subjects</i> predefined?",
        answer:
          "Scanning Subjects can be predefined in two ways: by importing from a shared library or by creating them independently. When creating reports independently, it's important to define the finding type, the instruments used for inspection, and the regulations and standards applicable to the inspection. After that, questions to be answered during the inspection are prepared. Additionally, conclusions and possible answers to the questions can be predefined to optimize data entry time in the field.",
      },
      question5: {
        question: "How Are Inspection Results Entered?",
        answer:
          "Answers to the predefined questions  are entered as text, in table format, as images, or by selecting from available options. Finally, a conclusion is formed—either positive or negative. A single expert report can encompass multiple scanning subjects.",
      },
      question6: {
        question: "What Are the Additional Features?",
        answer:
          "In addition to core functionalities, Inspect Master offers automatic saving of expert reports. Reports can be stored in the cloud or sent directly to clients via email. Another key advantage is the use of predefined, template-based answers to frequently asked questions, which can be automatically added to expert reports, as well as pre-designed inspection templates.",
      },
    },
  },
  sectionAbout: {
    title: "About Us",
    text: "<1>A few years ago, a long-term partner approached us with a challenge they faced in their daily operations. They needed a tool to help optimize processes, conduct inspections more efficiently, and generate expert reports. Thus, Inspect Master was born—a solution we designed to address everyday challenges and real business issues in the field of workplace safety compliance. </1> Inspect Master is a product developed by the IT software company Tremium Software, where our dedicated team works daily to implement the latest changes and functionalities. We strive to remain responsive to our partners' needs while also introducing enough innovations to expand the capabilities of our platform.",
  },
  sectionContact: {
    title: "Contact us",
    text: "Inspect Master provides you with the flexibility to choose the features that best suit your needs. For pricing information or to register for a free trial, please reach out via email at: <1>im.support@tremium.net</1> or by calling us at <2>060/366-02-71</2>",
  },
};
