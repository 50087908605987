import React, { createContext, ReactNode } from "react";
import PropTypes from "prop-types";
//import { ThemeProvider } from "@mui/material/styles";
import useTheme, { ColorModeContextType } from "../hooks/useTheme";
//import { ThemeProvider } from "@emotion/react";
import { ThemeProvider } from "styled-components";

export const ColorModeContext = createContext<ColorModeContextType>({
  changeTheme: (newTheme: string) => {},
  theme: { colors: {}, font: {} },
  currentTheme: undefined,
});
interface Props {
  children: ReactNode;
}

const ColorModeProvider: React.FC<Props> = ({ children }) => {
  const [changeTheme, theme, currentTheme] = useTheme();

  return (
    <ColorModeContext.Provider value={{ changeTheme, theme, currentTheme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
};

ColorModeProvider.propTypes = {
  children: PropTypes.node,
};

export default ColorModeProvider;
