import styled from 'styled-components';
export interface BurgerBottomProps {
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    isOpened: boolean
}
export interface BurgerBottomLineProps {
    isOpened: boolean
}
export const BurgerBottomContainer = styled.div<BurgerBottomProps>`
    margin-top:0px;
    z-index:999999;    
    background-color: ${(props) => props.isOpened ? 
                                        props.theme.colors.burger
                                        :props.theme.colors.transparent };
    padding: 16px 13px;
`;
export const BurgerBottomLine = styled.div<BurgerBottomLineProps>`
    background-color: ${(props) => props.isOpened ?
                                        props.theme.colors.white
                                        : props.theme.colors.burgerSecondary};
    width: 15px;
    padding: 0px;
    height: 3px;
    margin: 2px 0px;
    opacity:.8;
    z-index:999999;
`;
