import styled from "styled-components";
import { WhyContentProps } from "./WhyContent";

export const WhyContentContainer = styled.div<WhyContentProps>`
  gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  line-height: 24px;
  display: grid;

  @media screen and (max-width: 767px) {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    flex-flow: column;
    grid-template-rows: auto auto auto auto auto;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    display: flex;
  }
`;
