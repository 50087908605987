import styled from "styled-components";
import { TrialContentProps } from "./TrialContent";
import { ReactComponent as MarkIcon } from "../../../assets/images/svg/check-icon-orange.svg";

export const TrialContentContainer = styled.div<TrialContentProps>`
  width: 100%;

  @media screen and (max-width: 991px) {
    flex-flow: column;
  }
`;

export const TrialText = styled.div`
  width: 100%;
  margin-bottom: 40px;
  font-size: 20px;

  @media screen and (max-width: 991px) {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 16px;
  }

  @media screen and (max-width: 479px) {
    margin-bottom: 24px;
  }
`;

export const TrialInfo = styled.div`
  align-self: center;
  width: 50%;

  @media screen and (max-width: 991px) {
    width: 100%;
  }
`;
export const TrialTitle = styled.a`
  gap: 16px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  text-decoration: none;
  display: flex;

  @media screen and (max-width: 479px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
`;
export const TrialImage = styled.img`
  border-radius: 16px;
  max-width: 454px;
  box-shadow: 0 18px 18px ${(props) => props.theme.colors.boxShadow};
  vertical-align: middle;
  display: inline-block;

  @media screen and (max-width: 991px) {
    align-self: center;
  }
`;

export const TrialListItem = styled.div`
  gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;

  line-height: 24px;
`;

export const TrialBr = styled.div`
  margin-bottom: 15px;
`;

export const PricingCardText = styled.div`
  text-align: left;
  line-height: 150%;
  font-size: 14px;

  @media screen and (max-width: 991px) {
    font-size: 16px;
  }
`;

export const TrialList = styled.div`
  grid-column-gap: 49px;
  grid-row-gap: 17px;
  flex-flow: wrap;

  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;

  place-items: start;
  margin-bottom: 40px;
  padding-left: 0;
  list-style-type: none;
  display: grid;
  margin-top: 0;

  @media screen and (max-width: 767px) {
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 479px) {
    grid-template-rows: auto auto auto auto auto auto;
    grid-template-columns: 1fr;
  }
`;
export const TrialContactUs = styled.div`
  width: 100%;
`;

export const TrialIcon = styled.div`
  width: 21px;
`;
