import { BtnLink } from "./ButtonLink.styled";
import { Trans, useTranslation } from "react-i18next";
import { ReactNode } from "react";
import { Variant } from "@mui/material/styles/createTypography";

export interface ButtonProps {
  href: string;
  children?: ReactNode;
  largeButton?: boolean;
  color?:
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | "textPrimary"
    | "textSecondary"
    | "textDisabled";
  outlinedBtn?: boolean;
}

export const ButtonLink = (props: ButtonProps) => {
  const { t } = useTranslation();

  return <BtnLink {...props}>{props?.children}</BtnLink>;
};
