import styled from "styled-components";
import { FAQContentProps } from "./FAQContent";

export const FAQContentContainer = styled.div<FAQContentProps>`
  width: 100%;
  margin-bottom: 64px;

  flex-flow: column;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  overflow: visible;

  @media screen and (max-width: 991px) {
    .section-container {
      padding-top: 80px;
    }
  }

  @media screen and (max-width: 767px) {
    .section-container {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`;
