import {
  HeaderWrapper,
  HeaderContainerWrap,
  HeaderContainer,
  LogoWrapper,
  NavDesktop,
  ButtonWrapper,
  NavLink,
} from "./Header.styled";
import { ReactComponent as Logo } from "../../assets/images/svg/haeder-logo.svg";
import React from "react";
import { useTranslation } from "react-i18next";
import useDevice from "../../hooks/useDevice";
import { BurgerBottom } from "./Burger/BurgerBottom";
import NavBar from "./NavBar";
import LanguageSwitcher from "./LanguageSwitcher";

export const Header = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  const { isDesktop } = useDevice();
  const handleAvatarClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
    console.log(open);
  };
  const headerNavItems = [
    {
      title: "header.navItems.item1",
      link: "#",
    },
    {
      title: "header.navItems.item2",
      link: "#benefits",
    },
    {
      title: "header.navItems.item3",
      link: "#help",
    },
    {
      title: "header.navItems.item4",
      link: "#contact",
    },
  ];
  const { t } = useTranslation();
  return (
    <>
      <HeaderWrapper>
        <HeaderContainerWrap>
          <HeaderContainer>
            <LogoWrapper>
              <Logo height={"40px"} />
            </LogoWrapper>
            {isDesktop ? (
              <>
                <NavDesktop>
                  <ul>
                    {headerNavItems &&
                      headerNavItems.map((el, index) => {
                        return (
                          <li key={index}>
                            <NavLink href={el.link}> {t(el.title)}</NavLink>
                          </li>
                        );
                      })}
                  </ul>
                </NavDesktop>
                <LanguageSwitcher />
                <ButtonWrapper isMobile={false} isTablet={false}>
                  <a href="http://app.inspectmaster.net/" className="btn">
                    {t("header.navItems.item5")}
                  </a>
                </ButtonWrapper>
              </>
            ) : (
              <BurgerBottom isOpened={open} onClick={handleAvatarClick} />
            )}
          </HeaderContainer>
        </HeaderContainerWrap>
      </HeaderWrapper>
      {<NavBar isOpened={open} closeMenu={handleClose} />}
    </>
  );
};
