import {
  AboutUsContentContainer,
  AboutUsBr,
  AboutUsImage,
  AboutUsText,
} from "./AboutUsContent.styled";
import { Trans, useTranslation } from "react-i18next";
import { ReactNode } from "react";
import Image from "../../../assets/images/png/about-us-img.avif";

export const AboutUsContent = (props: AboutUsContentProps) => {
  const { t } = useTranslation();

  return (
    <AboutUsContentContainer>
      <AboutUsText>
        <Trans
          i18nKey={"sectionAbout.text"}
          components={{
            1: <AboutUsBr />,
          }}
        />
      </AboutUsText>
      <AboutUsImage src={Image}></AboutUsImage>
    </AboutUsContentContainer>
  );
};

export interface AboutUsContentProps {}
