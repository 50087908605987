import { FAQContentContainer } from "./FAQContent.styled";
import { Trans, useTranslation } from "react-i18next";
import { ReactNode, useState } from "react";
import { FAQContentCardProps, FAQQuestion } from "./FAQQuestion/FAQQuestion";
import { ButtonLink } from "../../ButtonLink/ButtonLink";
import { FAQContentText, FAQQuestions } from "./FAQQuestion/FAQQuestion.styled";

const questions: FAQContentCardProps[] = [
  {
    id: 1,
    title: "sectionFAQ.questions.question1.question",
    text: "sectionFAQ.questions.question1.answer",
    isOpen: false,
    handleClick: () => {},
  },
  {
    id: 2,
    title: "sectionFAQ.questions.question2.question",
    text: "sectionFAQ.questions.question2.answer",
    isOpen: false,
    handleClick: () => {},
  },
  {
    id: 3,
    title: "sectionFAQ.questions.question3.question",
    text: "sectionFAQ.questions.question3.answer",
    isOpen: false,
    handleClick: () => {},
  },
  {
    id: 4,
    title: "sectionFAQ.questions.question4.question",
    text: "sectionFAQ.questions.question4.answer",
    isOpen: false,
    handleClick: () => {},
  },
  {
    id: 5,
    title: "sectionFAQ.questions.question5.question",
    text: "sectionFAQ.questions.question5.answer",
    isOpen: false,
    handleClick: () => {},
  },
  {
    id: 6,
    title: "sectionFAQ.questions.question6.question",
    text: "sectionFAQ.questions.question6.answer",
    isOpen: false,
    handleClick: () => {},
  },
];

export const FAQContent = (props: FAQContentProps) => {
  const { t } = useTranslation();
  const [Questions, SetQuestion] = useState(questions);

  const handleClickEv = (id: number) => {
    if (questions.find((x) => x.id && x.isOpen == false)) {
      const questionsState = [...questions];
      questionsState.forEach((x) => {
        if (x.id !== id) {
          x.isOpen = false;
        } else {
          x.isOpen = !x.isOpen;
        }
      });

      SetQuestion(questionsState);
    }
  };

  return (
    <FAQContentContainer>
      <FAQQuestions>
        {Questions &&
          Questions.map((x, index) => (
            <FAQQuestion
              key={index}
              handleClick={() => handleClickEv(x.id)}
              text={x.text}
              title={x.title}
              isOpen={x.isOpen}
              id={x.id}
            ></FAQQuestion>
          ))}
      </FAQQuestions>
      <FAQContentText>{t("sectionFAQ.note")}</FAQContentText>
      <ButtonLink href="#contact" largeButton>
        {<Trans i18nKey={"common.contactUs"} />}
      </ButtonLink>
    </FAQContentContainer>
  );
};

export interface FAQContentProps {}
