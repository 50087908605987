import styled from 'styled-components';

interface ScrollTopContainerProps {
  isVisible: boolean;
}

export const ScrollTopContainer = styled.div<ScrollTopContainerProps>`
  position: fixed;
  bottom: 0px;
  right: 0;
  z-index: 100;
  background-color: ${(props) => props.theme.colors.lightOrange};
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  display: flex;
  inset: auto 4% 4% auto;
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')}; /* Corrected this line */
  cursor: pointer;
  transition: visibility 0.3s ease, opacity 0.3s ease;
  opacity: ${(props) => (props.isVisible ? '1' : '0')}; /* Optional: smooth visibility transition */
    & svg: {
        width: 25px;
    }
`;
