import { Link } from "@mui/material";
import styled, { css } from "styled-components";
import { ButtonProps } from "./ButtonLink";

export const BtnLink = styled.a<ButtonProps>`
  background-color: ${(props) => props.theme.colors.primary};

  border: 0px solid ${(props) => props.theme.colors.white};
  border-radius: 8px;

  mix-blend-mode: normal;
  transition: all 0.2s;
  box-shadow: 0 5px 8px ${(props) => props.theme.colors.boxShadow};
  padding: 10px 18px;
  //text-wrap: nowrap;

  text-decoration: none;
  text-transform: none;

  &:hover {
    background-color: ${(props) => props.theme.colors.primaryDarker};
    box-shadow: 0 7px 11px ${(props) => props.theme.colors.boxShadow};
  }

  &:active {
    background-color: ${(props) => props.theme.colors.primaryDarker};
  }

  &,
  &.css-1ruuf8c-MuiTypography-root-MuiLink-root {
    margin: initial;
    color: ${(props) => props.theme.colors.white};
    text-decoration: none;
    line-height: 20px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2px;
  }

  ${(props) =>
    props.largeButton &&
    css`
      //background-color: var(--primary);
      margin-bottom: 0px;
      padding: 16px 24px;

      &,
      &.css-1ruuf8c-MuiTypography-root-MuiLink-root {
        font-size: 24px;
      }
    `};

  ${(props) =>
    props.color == "secondary" &&
    css`
      &,
      &.css-f40qfk-MuiTypography-root-MuiLink-root {
        background-color: ${(props) => props.theme.colors.white};
        color: ${(props) => props.theme.colors.primaryDarker};

        margin: initial;
        text-decoration: none;
        line-height: 20px;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 2px;
      }
    `}

  ${(props) =>
    props.color == "secondary" &&
    css`
      box-shadow: none;
      transition: all 0.2s;

      &,
      &.css-f40qfk-MuiTypography-root-MuiLink-root {
        background-color: ${(props) => props.theme.colors.white};
        color: ${(props) => props.theme.colors.primaryDarker};
        transition: all 0.2s;

        margin: initial;
        text-decoration: none;
        line-height: 20px;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 2px;
      }

      &:hover {
        background-color: #f4eded;
        box-shadow: none;
      }
    `}

  ${(props) =>
    props.color == "secondary" &&
    props.largeButton &&
    css`
      &,
      &.css-f40qfk-MuiTypography-root-MuiLink-root {
        font-size: 20px;
      }
    `};

  ${(props) =>
    props.color == "secondary" &&
    props.outlinedBtn &&
    css`
      box-shadow: none;
      border: 2px solid ${(props) => props.theme.colors.white};
      &,
      &.css-f40qfk-MuiTypography-root-MuiLink-root {
        background-color: ${(props) => props.theme.colors.transparent};
        color: ${(props) => props.theme.colors.white};
      }

      &:hover {
        background-color: #ffffff30;
        box-shadow: none;
      }
    `}
`;
