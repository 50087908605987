import styled, { css } from "styled-components";
import { SectionProps } from "./Section";

export const SectionWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.transparent};
  font-size: 20px;

  max-width: 1120px;
  padding: 104px 40px 0 40px;
  margin: 0 auto 120px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: visible;

  @media screen and (max-width: 991px) {
    padding-top: 80px;
  }

  @media screen and (max-width: 767px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  @media screen and (max-width: 479px) {
    margin-bottom: 80px;
    padding-top: 56px;
  }
`;

export const SectionTitle = styled.h2`
  text-align: left;
  letter-spacing: -2px;
  width: 100%;
  font-size: 56px;
  font-weight: 400;
  line-height: 110%;

  margin-top: 0;
  margin-bottom: 104px;

  @media screen and (max-width: 767px) {
    margin-bottom: 80px;
    font-size: 48px;
  }

  @media screen and (max-width: 479px) {
    margin-bottom: 56px;
  }
`;

export const SectionDecoration = styled.img`
  z-index: -1;
  width: 100vw;
  max-width: 100vw;
  display: flex;
  position: absolute;
  height: auto;
  inset: auto 0% 40%;
  overflow: hidden;

  @media screen and (max-width: 991px) {
    top: -30%;
    bottom: 0%;
  }

  @media screen and (max-width: 767px) {
    top: -25%;
  }

  @media screen and (max-width: 479px) {
    width: 100vw;
    top: -15%;
  }
`;

export const SectionContainer = styled.div<SectionProps>`
  font-size: 20px;
  position: relative;
  overflow: hidden;

  ${(props: SectionProps) =>
    props.backgroundImage &&
    css`
      background-image: linear-gradient(
          ${(props) => props.theme.colors.gradientBackground1},
          ${(props) => props.theme.colors.gradientBackground1}
        ),
        linear-gradient(
          ${(props) => props.theme.colors.gradientBackground2},
          ${(props) => props.theme.colors.gradientBackground2}
        ),
        url(${props?.backgroundImage});

      background-position: ${props.backgroundPosition}; //0 0, 0 0, 50% 0%;
      background-size: auto, auto, cover;

      & ${SectionWrapper} {
        color: ${(props) => props.theme.colors.white};
      }
    `}
`;
