import { 
    PosibillityContainer, 
    PosibillityContainerLeft, 
    PosibillityContainerRight,
    PosibillityInnerContainer,
    Posibillity,
    PosibilityImg 
} 
from './InspectMasterPosibillity.styled'
import { useTranslation } from "react-i18next";
import {ReactComponent as PosibillityIcon} from '../../../assets/images/svg/check-icon.svg';
import PosibillityImage from '../../../assets/images/what-enable-im-img-p-500.avif';
export const InspectMasterPosibillity = () => {
    const { t } = useTranslation();
    const posibillities = t('sectionMakePossible.items', { returnObjects: true });
    let possibilitiesArray: string[] | null = null;
    if (posibillities && typeof posibillities === 'object') {
         possibilitiesArray = Object.values(posibillities);
    }
    return (
        <PosibillityContainer>
            <PosibillityContainerLeft>
                {
                    possibilitiesArray &&
                    possibilitiesArray?.map((el:string, index:number) => (
                        <PosibillityInnerContainer  key ={index}>
                            <div><PosibillityIcon/></div>
                            <Posibillity>{el}</Posibillity>
                        </PosibillityInnerContainer>
                    ))
                }
            </PosibillityContainerLeft>
            <PosibillityContainerRight>
                <PosibilityImg src={PosibillityImage}/>
            </PosibillityContainerRight>
        </PosibillityContainer>
    );
}