import { Link } from "@mui/material";
import {
  FAQWrapper,
  FAQHeader,
  FAQBody,
  FAQuestion,
  FAQIcon,
} from "./FAQQuestion.styled";
import { Trans, useTranslation } from "react-i18next";
import { ReactNode } from "react";

export interface FAQContentCardProps {
  id: number;
  children?: ReactNode;
  title?: string;
  text?: string;
  isOpen?: boolean;
  handleClick: (inx: number) => void;
}

export const FAQQuestion = (props: FAQContentCardProps) => {
  //props = { ...defaultProps, ...props };

  const { t } = useTranslation();

  return (
    <FAQWrapper>
      <FAQHeader {...props} onClick={() => props.handleClick(props.id)}>
        <FAQuestion {...props}>
          <Trans i18nKey={props?.title}></Trans>
        </FAQuestion>
        <FAQIcon {...props}></FAQIcon>
      </FAQHeader>
      <FAQBody {...props}>
        {props?.text && <Trans i18nKey={props?.text}></Trans>}
      </FAQBody>
    </FAQWrapper>
  );
};
