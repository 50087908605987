import React from "react";
import { DarkMode, LightMode } from "@mui/icons-material";

export const DARK_THEME = "dark";
export const LIGHT_THEME = "light";

export interface Theme {
  colors: object;
  font: object;
  //typography: object;
}

export const themes = [
  {
    name: LIGHT_THEME,
    icon: <LightMode />,
  },
  {
    name: DARK_THEME,
    icon: <DarkMode />,
  },
];

export const getNextTheme = (currentTheme: string) => {
  const currentThemeIndex =
    themes?.findIndex?.((singleTheme) => singleTheme?.name === currentTheme) ||
    0;
  const nextThemeIndex =
    currentThemeIndex === themes?.length - 1 ? 0 : currentThemeIndex + 1;
  return themes[nextThemeIndex]?.name;
};

export const themeStorageKey = "colorMode";
