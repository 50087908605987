import styled from "styled-components";
export const PosibillityContainer = styled.div`
    display:flex;
    flex-direction:row;
    @media screen and (max-width: 990px) {
        flex-direction:column;
    }
`;
export const PosibillityContainerLeft  = styled.div`
    width: 56%;
    margin-right: 80px;
    @media screen and (max-width: 990px) {
        width: 90%;
        margin:0px 5%;
    }
    @media screen and (max-width: 400px) {
        width: 84%;
        margin:0px 8%;
    }
`;
export const PosibillityContainerRight  = styled.div`
    width: calc(44% - 80px);
    display:flex;
    justify-content:center;
    @media screen and (max-width: 990px) {
        width: 100%;
        margin-top:40px;
    }
`;
export const PosibillityInnerContainer = styled.div`
    grid-column-gap: 4px;
    grid-row-gap: 4px;    
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    &:not(:last-child) {
        border-bottom: 1px solid ${(props) => props.theme.colors.dividerLinesOrange} ;
    }
`;
export const Posibillity = styled.div`
    display:block;
    font-size: 18px;
    line-height: 150%;
    @media screen and (max-width: 990px) {
        font-size: 16px;
        word-break: normal;        
    }
`;
export const PosibilityImg = styled.img`
    box-shadow: none;
    border-radius: 16px;
    width: 450px;
    height: 652px;
    @media screen and (max-width:767px) {
        width: 350px;
        height: auto;
    }
    @media screen and (max-width:360px) {
        width: 320px;
        height: auto;
    }
`