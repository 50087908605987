import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material';
import { LanguageSelect } from "./Header.styled";
import MenuItem from '@mui/material/MenuItem';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(i18n.language);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value as string;
    setSelectedValue(newValue);
    i18n.changeLanguage(newValue); // Use newValue here
  };
  const options = [
    { value: "en", label: "Eng" },
    { value: "sr", label: "Srb" },
  ];


  return (
    <div>
      <LanguageSelect
        value={selectedValue}
        onChange={handleChange}
        MenuProps={{
          disableScrollLock: true, 
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </LanguageSelect>
    </div>
  );
};

export default LanguageSelector;
