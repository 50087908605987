import styled from "styled-components";
import { AboutUsContentProps } from "./AboutUsContent";

export const AboutUsContentContainer = styled.div<AboutUsContentProps>`
  width: 100%;
  display: flex;

  @media screen and (max-width: 991px) {
    flex-flow: column;
  }
`;

export const AboutUsText = styled.div`
  margin-top: 0;
  margin-bottom: 0;

  padding-right: 80px;
  font-size: 18px;
  line-height: 150%;

  @media screen and (max-width: 991px) {
    margin-bottom: 80px;
    padding-right: 0;
  }

  @media screen and (max-width: 479px) {
    font-size: 16px;
    line-height: 130%;
  }
`;

export const AboutUsInfo = styled.div`
  align-self: center;
  width: 50%;

  @media screen and (max-width: 991px) {
    width: 100%;
  }
`;
export const AboutUsTitle = styled.a`
  gap: 16px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  text-decoration: none;
  display: flex;

  @media screen and (max-width: 479px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
`;
export const AboutUsImage = styled.img`
  border-radius: 16px;
  max-width: 454px;
  box-shadow: 0 18px 18px ${(props) => props.theme.colors.boxShadow};
  vertical-align: middle;
  display: inline-block;

  @media screen and (max-width: 991px) {
    align-self: center;
  }
  @media screen and (max-width: 600px) {
    margin:0px 10px;
    width:calc(100% - 20px);
  }
`;

export const TextLink = styled.a`
  color: ${(props) => props.theme.colors.white};
  font-weight: 700;
  text-decoration: none;
`;

export const AboutUsBr = styled.div`
  margin-bottom: 15px;
`;
