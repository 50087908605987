import { Box, Link, Typography } from "@mui/material";
import {
  FooterWrapper,
  CopyrightBox,
  FooterContentWrapper,
  FooterNavContent,
  InspectMasterLogo,
  SupportLink,
  FooterNavItems,
  FooterNavItem,
} from "./Footer.styled";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "../../assets/images/svg/logo.svg";

export interface FooterProps {
  //$backgroundColor: string;
}

const footerNavItems = [
  {
    title: "footer.navItems.item1",
    link: "#",
  },
  {
    title: "footer.navItems.item2",
    link: "#benefits",
  },
  {
    title: "footer.navItems.item3",
    link: "#",
  },
  {
    title: "footer.navItems.item4",
    link: "#help",
  },
  {
    title: "footer.navItems.item5",
    link: "#contact",
  },
];

export const Footer = (props: FooterProps) => {
  const { t } = useTranslation();

  return (
    <FooterWrapper>
      <FooterNavContent>
        <InspectMasterLogo></InspectMasterLogo>
        <FooterNavItems>
          {footerNavItems &&
            footerNavItems.map((el, index) => {
              return (
                <FooterNavItem key={index} href={el.link}>
                  {t(el.title)}
                </FooterNavItem>
              );
            })}
        </FooterNavItems>
        <SupportLink href="mailto:im.support@tremium.net">
          {t("common.supportLink")}
        </SupportLink>
      </FooterNavContent>
      <FooterContentWrapper>
        <Trans
          i18nKey={"footer.text"}
          components={{
            3: <CopyrightBox />,
            1: <Logo />,
            2: <Link href="https://tremium.net/" variant="body2" />,
          }}
        />
      </FooterContentWrapper>
    </FooterWrapper>
  );
};
