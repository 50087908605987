import {
  ContactContentContainer,
  ContactInfo,
  ContactInfoEmail,
  ContactInfoLink,
  ContactInfoPhone,
  ContactText,
  TextLink,
} from "./ContactContent.styled";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as MailOutline } from "../../../assets/images/svg/mail-outline.svg";
import { ReactComponent as CallOutline } from "../../../assets/images/svg/call-outline.svg";

export const ContactContent = (props: ContactContentProps) => {
  const { t } = useTranslation();

  return (
    <ContactContentContainer>
      <ContactText>
        <Trans
          i18nKey={"sectionContact.text"}
          components={{
            1: <TextLink href="mailto:im.support@tremium.net" />,
            2: <TextLink href="tel:0603660271" />,
          }}
        />
      </ContactText>
      <ContactInfo>
        <ContactInfoEmail href="mailto:im.support@tremium.net">
          <MailOutline />
          <ContactInfoLink>{t("common.supportLink")}</ContactInfoLink>
        </ContactInfoEmail>
        <ContactInfoPhone href="tel:0603660271">
          <CallOutline />
          <ContactInfoLink>{t("common.phoneNumber")}</ContactInfoLink>
        </ContactInfoPhone>
      </ContactInfo>
    </ContactContentContainer>
  );
};

export interface ContactContentProps {}
