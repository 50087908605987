import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PAGES } from "../constants/pages";
import ProtectedRoute from "./ProtectedRoute";
import { Roles } from "../enums/Roles";
import { PublicRoute } from "./PublicRoute";
import { HomePage } from "../pages/HomePage/HomePage";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route element={<PublicRoute />}>
          <Route path={PAGES.BASE.route} element={<HomePage />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoles={[Roles.SUPERADMIN]} />}>
        </Route>
        {/* <Route path="/reset-password" element={<PasswordResetContent />} />
        <Route path="/email-password-reset" element={<ResetPasswordForm />} /> */}
        <Route path="*" element={<div>404</div>} />
      </Routes>
    </Router>
  );
};
export default AppRouter;
