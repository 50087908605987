import styled from "styled-components";
import { FooterProps } from "./Footer";
import { Box } from "@mui/material";

import { ReactComponent as IMasterLogo } from "../../assets/images/svg/inspect-master-logo.svg";

export interface StyledTextDiv {
  $width?: string;
  $height?: string;
}

export const FooterWrapper = styled.footer<FooterProps>`
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black};
  //min-height: 3.1rem;
  padding: 100px 50px 60px;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;

  @media screen and (max-width: 767px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const FooterContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 4px;
  padding-top: 24px;
  font-weight: 300;
  line-height: 24px;
  border-top: 1px solid ${(props) => props.theme.colors.primaryDarker};

  & a {
    font-family: "Inter", sans-serif;
    line-height: 24px;
    color: ${(props) => props.theme.colors.black};
    font-weight: 700;
    text-decoration: underline;
  }

  @media screen and (max-width: 479px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 767px) {
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
  }
`;

export const CopyrightBox = styled.div`
  font-weight: 700;
`;

export const FooterNavContent = styled(Box)`
  display: flex;
  align-items: center;
  padding-bottom: 32px;

  @media screen and (max-width: 991px) {
    flex-flow: column;
  }

  @media screen and (max-width: 767px) {
    flex-flow: column;
  }
`;

export const FooterNavItems = styled(Box)`
  display: flex;
  gap: 32px;
  flex-flow: row;
  justify-content: center;
  align-self: center;
  align-items: center;
  height: 100%;

  padding: 38px 24px;
  border-left: 1px solid ${(props) => props.theme.colors.primaryDarker};

  @media screen and (max-width: 991px) {
    border-left: none;
  }

  @media screen and (max-width: 991px) {
    border-left-width: 0;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  @media screen and (max-width: 767px) {
    border-left-width: 0;
  }

  @media screen and (max-width: 479px) {
    flex-flow: wrap;
    margin-top: 40px;
  }
`;
export const FooterNavItem = styled.a`
  color: ${(props) => props.theme.colors.text};
  letter-spacing: 0.5px;
  align-self: auto;
  margin-top: 0;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  line-height: 24px;
`;

export const InspectMasterLogo = styled(IMasterLogo)`
  padding-right: 56px;

  @media screen and (max-width: 991px) {
    padding-right: 0px;
  }
`;

export const SupportLink = styled.a`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.black};
  font-weight: 600;
  text-decoration: none;

  height: 25px;
  border-bottom: 1px solid ${(props) => props.theme.colors.black};
  margin-left: auto;

  @media screen and (max-width: 991px) {
    margin-left: 0px;
  }
`;
