import {
  TrialContentContainer,
  TrialBr,
  TrialImage,
  TrialText,
  TrialList,
  TrialListItem,
  PricingCardText,
  TrialContactUs,
  TrialIcon,
} from "./TrialContent.styled";
import { Trans, useTranslation } from "react-i18next";
import { ReactNode } from "react";
import Image from "../../../assets/images/png/about-us-img.avif";
import { ButtonLink } from "../../ButtonLink/ButtonLink";
import { ReactComponent as MarkIcon } from "../../../assets/images/svg/check-icon-orange.svg";

export const TrialContent = (props: TrialContentProps) => {
  const { t } = useTranslation();

  const trialList = t("sectionTrial.trialList", {
    returnObjects: true,
  }) as string[];
  return (
    <TrialContentContainer>
      <TrialText>
        <Trans
          i18nKey={"sectionTrial.text1"}
          components={{
            1: <TrialBr />,
          }}
        />
      </TrialText>
      <TrialList>
        {trialList &&
          trialList.map((x, index) => {
            return (
              <TrialListItem key={index}>
                <TrialIcon>
                  <MarkIcon />
                </TrialIcon>
                <PricingCardText>{t(x)} </PricingCardText>
              </TrialListItem>
            );
          })}
      </TrialList>
      <TrialContactUs>
        <TrialText>
          <Trans
            i18nKey={"sectionTrial.text2"}
            components={{
              1: <TrialBr />,
            }}
          />
        </TrialText>
        <ButtonLink href="#contact">
          {<Trans i18nKey={"common.contactUs"} />}
        </ButtonLink>
      </TrialContactUs>
    </TrialContentContainer>
  );
};

export interface TrialContentProps {}
