import styled from "styled-components";
import { CtaContentProps } from "./CtaContent";

export const CtaContentContainer = styled.div<CtaContentProps>`
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SectionWrapper = styled.div<CtaContentProps>`
  background-color: ${(props) => props.theme.colors.transparent};
  color: ${(props) => props.theme.colors.black};
  font-size: 20px;

  max-width: 1200px;
  padding: 104px 40px 0 40px;
  margin: 0 auto 120px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: visible;
`;

export const CtaButtons = styled.div`
  gap: 40px;
  display: flex;

  @media screen and (max-width: 767px) {
    flex-flow: column;
  }
`;

export const CtaText = styled.div`
  text-align: center;
  padding-bottom: 40px;
  font-size: 24px;
  line-height: 150%;

  @media screen and (max-width: 479px) {
    font-size: 20px;
  }
`;

export const CtaImage = styled.img`
  background-color: url(${(props) => props.src});
  width: 650px;
  height: 433px;
  margin-top: 64px;
`;
